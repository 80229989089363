@import '@/styles/sizes.scss';
@import '@/styles/global.scss';

.item {
    flex: 1;
    padding: 1rem;
    border-radius: $border-radius;
    background-color: $primaryBackground;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 1);
    box-shadow: 0px 8px 16px 0px rgba(210, 204, 217, 0.25);
    display: flex;
    flex-direction: column;
    position: relative;

    .content {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        margin-bottom: 2.5rem;

        .header {
            text-align: left;
            flex-grow: 2;

            .title {
                font-size: 1rem;
                margin-bottom: 0.625rem;
                font-weight: 600;
                color: $primaryText;
            }

            .label {
                font-size: 0.875rem;
                color: $secondaryText;
            }
        }

        .score {
            flex-grow: 1;
            font-size: $font-size-title;
            margin-bottom: 0.9375rem;
            display: flex;
            justify-content: center;
            align-items: center;

            .scoreContent {
                display: flex;
                gap: 0.625rem;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .value {
                    font-size: 1.25rem;
                    font-weight: 700;

                    &.best {
                        color: #347c00;
                    }

                    &.good {
                        color: #347c00;
                    }

                    &.mid {
                        color: rgba(195, 130, 0, 1);
                    }

                    &.bad {
                        color: rgba(243, 57, 57, 0.32);
                    }

                    &.veryBad {
                        color: rgba(243, 57, 57, 1);
                    }
                }

                .label {
                    background: rgba(245, 240, 250, 1);
                    padding: 0.25rem 0.75rem;
                    border-radius: 1rem;
                    font-size: 0.875rem;
                    color: $secondaryText;
                }
            }

            .icon {
                width: 5rem;
                height: 3rem;
            }
        }
    }

    .loanValue {
        text-align: left;
        font-size: 0.75rem;

        @include lg {
            position: absolute;
            text-align: right;
            font-size: 0.75rem;
            bottom: 1.7rem;
            right: 1rem;
        }
    }
}
