@import '@/styles/sizes.scss';
@import '@/styles/global.scss';

.wrapper {
    padding: $global-spacing;
    padding-bottom: 4rem;
    overflow-y: auto;
    min-height: 82vh;

    .container {
        @include container;
        display: flex;
        flex-direction: column;
    }
    .header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5rem;
        gap: 0.5rem;

        .title {
            font-size: 0.875rem;
            font-weight: 600;
        }
        .text {
            font-size: 0.875rem;
            color: $secondaryText;
        }

        @include sm {
            flex-direction: row;
        }
    }
    .placeholder {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 15rem;
        border-radius: $border-radius;
        background-color: $secondaryBackground;
        margin-bottom: 1.25rem;

        .placeholderTitle {
            margin-bottom: 0.5rem;
        }
    }
    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        gap: 1rem;
        border-radius: $border-radius;
        margin-bottom: 1.25rem;

        @include lg {
            flex-direction: row;
        }
    }

    .cardsWrapper {
        display: flex;
        align-items: stretch;
        gap: 1.25rem;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            gap: 1rem;
        }
    }
}
