@import '@/styles/global.scss';
.feature {
    border-radius: $border-radius;
    margin-bottom: 1.25rem;
    padding: calc($global-spacing * 2) calc($global-spacing * 1.5);

    .icon {
        font-size: 2rem;
        margin-bottom: $global-spacing;
    }

    .title {
        font-size: 1.25rem;
        margin-bottom: $global-spacing;
        color: $primaryText;
    }

    .content {
        font-size: 1rem;
        color: #666;
    }

    &:hover {
        box-shadow: $box-shadow;
    }
}
