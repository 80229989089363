@import '@/styles/sizes.scss';
@import '@/styles/global.scss';

.wrapper {
    padding: $global-spacing;

    .container {
        @include container;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            height: 2.5rem;
            width: auto;
            cursor: pointer;
        }

        .actions {
            display: flex;
            align-items: center;
            gap: 1rem;
        }

        @include lg {
            .logo {
                height: 4rem;
            }
        }
    }

    .hideOnMobile {
        display: none;
    }

    @include sm {
        padding: $global-spacing 2rem;
    }
    @include md {
        padding: 1.5rem 2rem;
        display: flex;

        .hideOnMobile {
            display: flex;
        }
    }
}
