@import '@/styles/global.scss';

.emailCard {
    background-color: #9639f3;

    .form {
        display: flex;
        flex-direction: column;
    }

    .input {
        height: 2.75rem;
        border-radius: $border-radius;
        padding: 0.75rem;
        font-weight: 500;
        font-size: 1rem;
        border: none;
        margin-bottom: 1rem;
    }

    .title {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 0.75rem;
    }
    .text {
        font-size: 0.875rem;
        margin-bottom: 0.75rem;
    }

    .button {
        height: 2.5rem;
        border-radius: $border-radius;
        font-weight: 600;
        font-size: 1rem;
        background-color: #e9e2f0;
        color: #7017c9;
        border: none;
    }

    .image {
        position: absolute;
        display: block;
        right: -25%;
        bottom: -10%;
        width: 16rem;
        height: 16rem;
        opacity: 0.15;

        &.tada {
            font-size: 12rem;
        }
    }

    .button2 {
        background-color: rgba(#f0e4e2, 0.48);
        border-radius: $border-radius;
        width: 13.125rem;
        height: 2.5rem;
        display: flex;
        border: none;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        font-weight: 500;
        text-decoration: none;
        color: $alternativeText;
    }
}
