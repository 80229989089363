@import '@/styles/global.scss';

.input {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    padding: 0 $global-spacing;
    width: 100%;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    border: 1px solid transparent;

    label {
        font-size: 1rem;
        color: $primary;
        opacity: 0.5;
    }

    input {
        height: 3.5rem;
        width: 100%;
        padding: 0 1rem;
        font-size: 1rem;
        outline: none;
        border: none;
        border-radius: $border-radius;
        color: $primaryText;
        background: $secondaryBackground;

        &:disabled {
            opacity: 0.5;
        }
    }

    &.small {
        input {
            height: 2.5rem;
        }
    }

    .icon {
        cursor: pointer;
        opacity: 0.7;
        transition: 0.3s all ease-in-out;

        &:hover {
            transform: scale(1.1);
            opacity: 1;
        }
    }

    &:hover {
        border: 1px solid rgba(218, 202, 235, 1);
    }

    &:focus-within {
        border: 1px solid rgba(218, 202, 235, 1);
    }
}
