@import '@/styles/global.scss';
@import '@/styles/sizes.scss';

.home {
    background-color: $primaryBackground;
    padding: 1rem;
    font-family: $font-family;
    display: flex;
    flex-direction: column;
    gap: 5rem;

    @include sm {
        padding: 2rem;
        padding-top: 0;
    }

    .wrapper {
        @include container;
        min-height: 70vh;
        display: flex;
        align-items: center;
        background-color: $secondaryBackground;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        flex-direction: column;
        margin: 0 auto;
        padding: calc($global-spacing * 5) $global-spacing;

        .banner {
            border-radius: $border-radius;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: flex-start;
            gap: 3rem;

            @include lg {
                max-width: 50%;
                padding: 3rem;
            }

            .content {
                display: flex;
                flex-direction: column;

                .poweredBy {
                    margin-bottom: $global-spacing;
                    border-radius: $border-radius;
                    background-color: $tertiaryBackground;
                    padding: calc($global-spacing / 2) $global-spacing;
                    width: fit-content;

                    span {
                        font-size: $font-size-small-subtitle;
                        color: $secondaryText;
                        font-weight: $font-weight-bold;
                    }
                }

                .title {
                    font-size: 2rem;
                    font-weight: $font-weight-bold;
                    margin-bottom: 1rem;

                    @include lg {
                        font-size: 2rem;
                    }
                }

                .description {
                    font-size: 1rem;
                    color: $secondaryText;
                    margin-bottom: $global-spacing;
                    line-height: 1.5;
                }
            }

            .inputContainer {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                width: 100%;

                .inputLabel {
                    color: $secondaryText;
                    font-size: 1rem;
                    font-weight: 600;
                    line-height: 19.36px;
                    margin-left: 0.25rem;
                }
            }
        }

        .imageContainer {
            display: flex;
            justify-content: center;
            align-items: center;

            .image {
                width: 70%;
                margin-top: 2rem;
            }
        }

        @include lg {
            padding: 2rem;
            flex-direction: row;

            .banner {
                .content {
                    .title {
                        font-size: 2rem;
                    }
                }
            }
        }
        @include xl {
            .banner {
                .content {
                    .title {
                        font-size: 3rem;
                        margin-bottom: 1.5rem;
                    }
                }
            }
        }
    }

    .features {
        @include container;
        padding-bottom: 2rem;
        display: flex;
        flex-direction: column;

        @include lg {
            flex-direction: row;
        }
    }

    .button {
        background-color: $primary;
        color: $alternativeText;
        padding: calc($global-spacing * 1.5) calc($global-spacing * 2);
        border-radius: $border-radius;
        border: none;
        cursor: pointer;
        font-size: 1rem;
        min-width: 8rem;
        align-items: center;
        justify-content: center;
        transition: 0.3s all ease-in-out;

        &:hover {
            background-color: #6c5ce7;
        }

        &:active {
            background-color: #7a42b2;
        }
    }
}
