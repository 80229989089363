@import '@/styles/global.scss';

.extensionCard {
    background-color: $primaryAccent;

    .text {
        max-width: 12.5rem;
    }
    .image {
        position: absolute;
        display: block;
        right: -25%;
        bottom: -10%;
        width: 16rem;
        height: 16rem;
        opacity: 0.15;
    }

    .button2 {
        background-color: rgba(#f0e4e2, 0.48);
        border-radius: $border-radius;
        width: 13.125rem;
        height: 2.5rem;
        display: flex;
        border: none;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        font-weight: 500;
        text-decoration: none;
        color: $alternativeText;
    }

    .title {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 0.75rem;
    }
    .text {
        font-size: 0.875rem;
        margin-bottom: 0.75rem;
    }
}
