@import '@/styles/global.scss';

.connectWalletButton {
    background-color: $primary;
    color: $alternativeText;
    padding: calc($global-spacing * 1.5) calc($global-spacing * 2);
    border-radius: $border-radius;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    min-width: 8rem;
    align-items: center;
    justify-content: center;
    transition: 0.3s all ease-in-out;

    &:hover {
        background-color: #6c5ce7;
    }

    &:active {
        background-color: #7a42b2;
    }

    .walletAddress {
        color: $alternativeText;
        font-size: 0.875rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        .walletPreview {
            width: 1.25rem;
            height: 1.25rem;
        }
    }

    @include lg {
        min-width: 12rem;
    }
}
