@import '@/styles/sizes.scss';
@import '@/styles/global.scss';

.Page {
    display: flex;
    flex-direction: column;

    @include lg {
        padding: $global-spacing;
    }
}
